import React, { useEffect } from "react";
import propTypes from "prop-types";
import { Modal } from "@molecules";
import classNames from "classnames";
import loadable from "@loadable/component";
import { LazyMotion } from "framer-motion";
import useLang from "@hooks/useLang";
import { useAppState } from "@state";
import BrowserWarning from "../atoms/BrowserWarning";

const GdprBanner = loadable(() => import("@organisms/GdprBanner"));
const Header = loadable(() => import("@organisms/Header"));
const AudioPlayer = loadable(() => import("@organisms/AudioPlayer"));
const SideScrollModal = loadable(() => import("@organisms/SideScrollModal"));
const Transcript = loadable(() => import("@organisms/TranscriptModal"));

const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};

const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({
  children,
  path,
  className: _className,
  pageContext,
}) => {
  console.log("context", pageContext);
  const [, dispatch] = useAppState();

  // load animation features
  useEffect(() => {
    resolve(import("@utils/animationFeatures").then(r => r.domMax));
  }, []);

  useEffect(() => {
    dispatch({ type: "setLang", lang: pageContext?.lang || "en" });
  }, []);

  const lang = useLang();
  // the layout
  return (
    <LazyMotion features={loadFeatures}>
      <div
        id="app_layout"
        className={classNames(
          "texture-pattern transistion relative bg-viridian duration-300",
          _className
        )}
      >
        <div className={classNames("transistion duration-700 ease-out")}>
          <Header
            locales={pageContext.locales}
            pageLang={pageContext?.lang}
            inverse={pageContext?.theme === "viridian"}
          />
          {children}
        </div>
        <BrowserWarning />
      </div>
      {/* z-700 */}
      <SideScrollModal />
      {/* z-800 */}
      <Modal />
      {/* z-900 */}
      <Transcript />
      {/* z-950 */}
      {typeof window !== "undefined" && <AudioPlayer />}
      {process.env.GATSBY_GTM_ID && <GdprBanner />}
    </LazyMotion>
  );
};

DefaultLayout.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
  className: propTypes.string,
};

DefaultLayout.defaultProps = {
  className: "bg-viridian",
};

export default DefaultLayout;
