module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Equality Fund Annual Report","short_name":"Equality Fund AR","start_url":"/","background_color":"#4B7F80","theme_color":"#4B7F80","display":"standalone","icon":"./static/images/icon.png","lang":"en","localize":[{"start_url":"/fr/","lang":"fr","name":"Rapport Annuel du Fonds Égalité 2021-2022","short_name":"RA du Fonds Égalité"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9449ccc86c6b7deec84efe8468c6623a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["**/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
