import "./src/pcss/index.pcss";
import { AnimatePresence } from "framer-motion";

import React from "react";
import Default from "@templates/Default";
import ErrorBoundary from "@organisms/ErrorBoundary";
import { AppStateProvider } from "@state";

// const transitionDelay = 200;

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   if (location.action === "PUSH") {
//     window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
//   } else {
//     const savedPosition = getSavedScrollPosition(location);
//     window.setTimeout(
//       () => window.scrollTo(...(savedPosition || [0, 0])),
//       transitionDelay
//     );
//   }
//   return false;
// };

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props;
  return (
    <Default pageContext={pageContext}>
      <AnimatePresence mode="wait" initial={false}>
        {element}
      </AnimatePresence>
    </Default>
  );
};

export const wrapRootElement = ({ element }) => {
  return (
    <ErrorBoundary>
      <AppStateProvider>{element}</AppStateProvider>
    </ErrorBoundary>
  );
};

// export const onClientEntry = () => {
//   if (process.env.NODE_ENV !== "production") {
//     const whyDidYouRender = require("@welldone-software/why-did-you-render");
//     whyDidYouRender(React, {
//       trackAllPureComponents: true,
//     });
//   }
// };
