import React from "react";
import { Text } from "@atoms";
import useLang from "@hooks/useLang";
import t from "@utils/t";

const ArchFrame = ({ children, caption, className }) => {
  const lang = useLang();
  return (
    <div className={className}>
      <div className="relative overflow-hidden rounded-t-full bg-black pb-[133%]">
        {children}
      </div>
      {caption && (
        <div className="mt-6 flex min-w-[10rem] max-w-[33%] flex-wrap gap-1">
          <Text variant="caption">{t("Photo courtesy of", lang)}</Text>
          <Text className="whitespace-nowrap" variant="caption">
            {caption}
          </Text>
        </div>
      )}
    </div>
  );
};

export default ArchFrame;
