import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { m } from "framer-motion";

const Section = ({ id, children, className }) => {
  const ref = useRef();
  useEffect(() => {
    const { hash } = window.location;
    setTimeout(() => {
      if (hash === `#${id}`) {
        ref.current.scrollIntoView({
          // behavior: "smooth",
        });
      }
    }, 0);
  }, []);
  return (
    <m.section
      // todo: might want to use this? breaks with transition currently
      // onViewportEnter={() => {
      //   if (window.history.pushState) {
      //     window.history.pushState(null, null, `#${id}`);
      //   } else {
      //     window.location.hash = `#${id}`;
      //   }
      // }}
      className={classNames(
        className,
        "texture-pattern relative z-[100] bg-viridian"
      )}
      ref={ref}
      id={id}
    >
      {children}
    </m.section>
  );
};

export default Section;
