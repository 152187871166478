import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Text } from "@atoms";
import { useAppState } from "@state";
import { m } from "framer-motion";
import { navigate } from "gatsby";
import useLang from "@hooks/useLang";

const LanguageToggle = ({ pageLang, locales }) => {
  const [, dispatch] = useAppState();

  const lang = useLang();

  const languageText =
    lang === "en"
      ? "Currently viewing english. Click to change to french"
      : "Actuellement affiché en français. Cliquez pour passer à l'anglais";

  useEffect(() => {
    console.log(pageLang, lang, locales);
    if (pageLang && lang && pageLang !== lang) {
      const prefix = lang !== "fr" ? "/" : "/fr/";
      const uri = locales[lang] === "__home__" ? "" : locales[lang];
      navigate(prefix + uri);
    }
  }, [pageLang, lang]);

  return (
    <button
      className={classNames("w-12 uppercase transition duration-300")}
      type="button"
      onClick={() => dispatch({ type: "toggleLang" })}
      aria-label={languageText}
    >
      <div className="flex w-full justify-between">
        <Text
          variant="sm"
          className={classNames("transition duration-300", {
            "opacity-30": lang !== "en",
          })}
        >
          en
        </Text>
        <Text
          variant="sm"
          className={classNames("transition duration-300", {
            "opacity-30": lang !== "fr",
          })}
        >
          fr
        </Text>
      </div>
      <div
        className={classNames("item-center relative flex w-full", {
          "justify-start": lang === "en",
          "justify-end": lang === "fr",
        })}
      >
        <m.div layout className="h-1.5 w-1.5 rounded-full bg-current" />
        <div className="absolute inset-0 my-auto h-[4px] w-full bg-current" />
      </div>
    </button>
  );
};

LanguageToggle.defaultProps = {};

export default LanguageToggle;
